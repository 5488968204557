import { GatsbySeo } from 'gatsby-plugin-next-seo'
import SizeGuide from 'src/components/sections/SizeGuide'

function Page() {
  return (
    <>
      <GatsbySeo title="Guia de tamanhos | Decathlon" noindex nofollow />
      <SizeGuide />
    </>
  )
}

export default Page
